import React from "react";
// import Comments from "./Comments";
// import Sidebar from "./Sidebar";
import styles from "./career.module.css";
import CareerSidebar from "./CareerSidebar";

export default function CareerDetails() {
  return (
    <>
      <div className="overflow-hidden">
        <section
          className={`video-promo ptb-100 blog-hero`}
          id="blog-header"
          style={{
            background:
              "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url('/assets/img/career.jpg') no-repeat center center / cover",
          }}
        >
          <div className={styles.home_section}>
            <h1 className="mt-4 text-white scale-down">Join Our Team</h1>
          </div>
        </section>
      </div>
      <div className="module ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <article className="post">
                <div className="post-preview">
                  {/* <img
                    src="assets/img/blog/1-w.jpg"
                    alt="article"
                    className="img-fluid"
                  /> */}
                </div>
                <div className="post-wrapper">
                  <div className={`post-header ${styles.main_h1}`} data-aos="fade-right">
                    <h1 className="post-title">We Are Hiring!</h1>
                  </div>
                  <div className={`post-content ${styles.single_blog}`}>
                    <p data-aos="fade-right">
                      We are looking for a talented and competitive Inside Sales
                      Representative that thrives in a quick sales cycle
                      environment. The successful candidate will play a
                      fundamental role in achieving our ambitious customer
                      acquisition and revenue growth objectives. You must be
                      comfortable making dozens of calls per day, working with
                      channel partners, generating interest, qualifying
                      prospects and closing sales.
                    </p>

                    <h5 style={{ fontWeight: "bold", fontSize: "16px" }} data-aos="fade-right">
                      Responsibilities
                    </h5>
                    <p>
                      <ul className="list-disc" data-aos="fade-right">
                        <li>
                          Source new sales opportunities through inbound lead
                          follow-up and outbound cold calls and emails
                        </li>
                        <li>Understand customer needs and requirements</li>
                        <li>
                          Route qualified opportunities to the appropriate sales
                          executives for further development and closure
                        </li>
                        <li>Close sales and achieve quarterly quotas</li>
                        <li>
                          Research accounts, identify key players and generate
                          interest
                        </li>
                        <li>
                          Maintain and expand your database of prospects within
                          your assigned territory
                        </li>
                        <li>
                          Team with channel partners to build pipeline and close
                          deals
                        </li>
                        <li>Perform effective online demos to prospects</li>
                      </ul>
                    </p>

                    <h5 style={{ fontWeight: "bold", fontSize: "16px" }} data-aos="fade-right">
                      Requirements
                    </h5>

                    <p data-aos="fade-right">
                      <ul className="list-disc">
                        <li>Proven inside sales experience</li>
                        <li>Track record of over-achieving quota</li>
                        <li>
                          Strong phone presence and experience dialing dozens of
                          calls per day
                        </li>
                        <li>
                          Proficient with corporate productivity and web
                          presentation tools
                        </li>
                        <li>
                          Experience working with Salesforce.com or similar CRM
                        </li>
                        <li>
                          Excellent verbal and written communications skills
                        </li>
                        <li>Strong listening and presentation skills</li>
                        <li>
                          Ability to multi-task, prioritize, and manage time
                          effectively
                        </li>
                        <li>BA/BS degree or equivalent</li>
                      </ul>
                    </p>

                    <h5 style={{ fontWeight: "bold", fontSize: "16px" }} data-aos="fade-right">
                      Benefits
                    </h5>

                    <p data-aos="fade-right">
                      <ul className="list-disc">
                        <li>Competitive salary</li>
                        <li>Health insurance</li>
                        <li>Flexible working hours</li>
                        <li>Work from home</li>
                        <li>Performance bonus</li>
                        <li>Travel allowance</li>
                        <li>Training and development</li>
                      </ul>
                    </p>

                    <h5 style={{ fontWeight: "bold", fontSize: "16px" }} data-aos="fade-right">
                      How to Apply?
                    </h5>

                    <p data-aos="fade-right">
                      Please send your resume to{" "}
                      <a href="mailto:info@regera.tech">info@regera.tech</a>
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <CareerSidebar />
          </div>
        </div>
      </div>
    </>
  );
}
