import React from "react";
import { useForm } from "@formspree/react";
import Swal from "sweetalert2";
// import { subsCribe } from "../../api/internal";

export default function Subsribe() {
  // const [emailAddress, setEmail] = useState("");
  // const handleClick = async () => {
  //   const data = {
  //     emailAddress,
  //   };
  //   try {
  //     const response = await subsCribe(data);
  //     if (response === 201) {
  //       console.log("Subscribed successfully!");
  //     } else if (response.code === "BAD_REQUEST_ERROR") {
  //       console.log("error in subscription!");
  //     }
  //   } catch (error) {
  //     return error;
  //   }
  // };
  const [state, handleSubmit] = useForm("mvoezevb");
  if (state.succeeded) {
    // return <p>Thanks for joining!</p>;
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Subscribed successfully!",
      showConfirmButton: false,
      timer: 1500,
    });

    const form = document.getElementById("subscribeForm");
    form.reset();
  }
  return (
    <>
      <div className="shape-img subscribe-wrap">
        <img
          src="assets/img/footer-top-shape.png"
          alt="footer shape"
          className="img-fluid"
        />
        <div className="container">
          <div className="row justify-content-center" data-aos="fade-up">
            <div className="col-md-6">
              <div
                // method="post"
                className="subscribe-form subscribe-form-footer d-none d-md-block d-lg-block"
              >
                <form
                  onSubmit={handleSubmit}
                  className="d-flex align-items-center"
                  id="subscribeForm"
                >
                  <input
                    // value={(e) => setEmail(e.target.value)}
                    type="text"
                    className="form-control input"
                    id="email-footer"
                    name="email"
                    placeholder="info@regera.tech"
                  />
                  <input
                    // onClick={handleClick}
                    type="submit"
                    className="button btn solid-btn"
                    id="submit-footer"
                    value="Subscribe"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
