import React from "react";
// import { email } from "../../api/internal";
import styles from "./Contact.module.css";
import { useForm } from "@formspree/react";
import Swal from "sweetalert2";

export default function Contact({ bgColor }) {
  // const [emailAddress, setEmail] = useState("");
  // const [name, setName] = useState("");
  // const [phone, setPhone] = useState("");
  // const [companyName, setCompanyName] = useState("");
  // const [message, setMessage] = useState("");

  // const handleClick = async () => {
  //   const data = {
  //     emailAddress,
  //     name,
  //     phone,
  //     companyName,
  //     message,
  //   };
  //   try {
  //     const response = await email(data);
  //     if (response.status === 201) {
  //       console.log("email sent!");
  //     } else if (response.code === "BAD_REQUEST_ERROR") {
  //       console.log("error in sending email!");
  //     }
  //   } catch (error) {
  //     return error;
  //   }
  // };
  const [state, handleSubmit] = useForm("xayrqrpg");
  if (state.succeeded) {
    // return <p>Thanks for joining!</p>;
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Your message has been sent!",
      showConfirmButton: false,
      timer: 1500
    });

    const form = document.getElementById("contactForm");
    form.reset();
  }
  return (
    <>
      <section
        id="contact"
        className={`contact-us pt-100 pb-0   ${bgColor ? "gray-light-bg" : ""}`}
        style={{ paddingTop: "1rem" }}
      >
        <div className={`container ${styles.contact_main} `}>
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-5" data-aos="fade-right">
              <div className={`section-heading `}>
                <h2>Contact Us</h2>
                <p>
                  Our team is always listening. Fill out our contact form or
                  give us a call at your convenience, and we’ll be in touch
                  right away.
                </p>
              </div>
              <div className="footer-address">
                {/* <h6 className="mb-0">Head Office</h6>
                <p className="mt-1">
                  100 West Big Beaver Road,
                  <br />
                  Troy, Michigan 48084
                </p> */}
                <ul>
                  {/* <li>
                    <span>Phone: +61 2 8376 6284</span>
                  </li> */}
                  <li>
                    <span>
                      Email :
                      <a href="mailto:info@regera.tech">info@regera.tech</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7" data-aos="fade-left">
              <form
                onSubmit={handleSubmit}
                action="#"
                method="POST"
                id="contactForm"
                className="contact-us-form"
              >
                {/* <h5>Reach us quickly</h5> */}
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        // value={(e) => setName(e.target.value)}
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        placeholder="Enter name"
                        required="required"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        // value={(e) => setEmail(e.target.value)}
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        required="required"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        // value={(e) => setPhone(e.target.value)}
                        type="text"
                        name="phone"
                        defaultValue=""
                        className="form-control"
                        id="phone"
                        placeholder="Your Phone"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        // value={(e) => setCompanyName(e.target.value)}
                        type="text"
                        name="company"
                        defaultValue=""
                        size="40"
                        className="form-control"
                        id="company"
                        placeholder="Your Company"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        // value={(e) => setMessage(e.target.value)}
                        name="message"
                        id="message"
                        className="form-control"
                        rows="7"
                        cols="25"
                        placeholder="Message"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <button
                      // onClick={handleClick}
                      type="submit"
                      className="btn solid-btn"
                      id="btnContactUs"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-message"></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
