import React from "react";
import { Link } from "react-router-dom";

export default function CareerSidebar() {
  return (
    <>
      <div className="col-lg-4 col-md-12">
        <div className="sidebar-left pr-4">
          <aside className="widget widget-recent-entries-custom">
            <div className="widget-title">
              <h6>Related Jobs</h6>
            </div>
            <ul>
              <li className="clearfix" data-aos="fade-right">
                <div className="wi">
                  <Link to="/sales-executive" className="h-100">
                    <img
                      src="assets/img/sales-bg.jpg"
                      alt="recent post"
                      className="img-fluid rounded h-100"
                    />
                  </Link>
                </div>
                <div className="wb">
                  <h6>
                    <Link to="/sales-executive">
                      Sales Executive
                    </Link>
                  </h6>
                  <p style={{ fontSize: "13px" }}>Hybrid</p>
                </div>
              </li>
              <li className="clearfix" data-aos="fade-right">
                <div className="wi">
                  <Link to="/odoo-dev">
                    <img
                      src="assets/img/odoo-bg.jpg"
                      alt="recent post"
                      className="img-fluid rounded"
                    />
                  </Link>
                </div>
                <div className="wb">
                  <h6>
                    <Link to="/odoo-dev">
                      Odoo Developer
                    </Link>
                  </h6>
                  <p style={{ fontSize: "13px" }}>Onsite</p>
                </div>
              </li>
              <li className="clearfix" data-aos="fade-right">
                <div className="wi">
                  <Link to="/dynamics-dev">
                    <img
                      src="assets/img/dynamics-bg.jpg"
                      alt="recent post"
                      className="img-fluid rounded"
                    />
                  </Link>
                </div>
                <div className="wb">
                  <h6>
                    <Link to="/dynamics-dev">
                      Dynamics Developer
                    </Link>
                  </h6>
                  <p style={{ fontSize: "13px" }}>Remote</p>
                </div>
              </li>
            </ul>
          </aside>


          {/* <aside className="widget widget-tag-cloud">
            <div className="widget-title">
              <h6>Tags</h6>
            </div>
            <div className="tag-cloud">
              <a href="#//">e-commerce</a>
              <a href="#//">portfolio</a>
              <a href="#//">responsive</a>
              <a href="#//">bootstrap</a>
              <a href="#//">business</a>
              <a href="#//">corporate</a>
            </div>
          </aside> */}
        </div>
      </div>
    </>
  );
}
