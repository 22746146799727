import React from "react";
import styles from "./FeatureImgTwo.module.css";
import { Link } from "react-router-dom";
export default function FeatureImgTwo() {
  return (
    <>
      <section id="insights" className={`about-us ptb-100 gray-light-bg  `}>
        {/* customzied insight */}
        <div className={styles.insight_main}>
          <div
            className={`about-content-left section-heading ${styles.insight_left}`}
          >
            {/* <h2>Drive Business Results</h2> */}

            {/* <div className="single-feature mb-4 mt-5">
              <div className="icon-box-wrap d-flex align-items-center mb-2">
                <div className="mr-3 icon-box">
                  <img
                    src="assets/img/image-icon-1.png"
                    alt="icon "
                    className="img-fluid"
                  />
                </div>

                <p className="mb-0">
                  ERP solutions are a centralized platform enabling streamlined
                  data management and integrated business processes. They are
                  integral to a fully functional operational environment, from
                  finance, human resources, and customer to supply chain
                  management.
                </p>
              </div>
            </div>
            <div className="single-feature mb-4">
              <div className="icon-box-wrap mb-2">
                <div className="mr-3 icon-box">
                  <img
                    src="assets/img/image-icon-2.png"
                    alt="icon "
                    className="img-fluid"
                  />
                </div>
                <p className="mb-0">
                  IIoT is a crucial innovation in the manufacturing sector,
                  allowing businesses to improve operations and predictive
                  maintenance while facilitating informed business
                  decision-making. Manufacturers can collect and analyze
                  real-time data with the ability to connect to and monitor
                  industrial equipment, allowing for enhanced visibility and
                  proactive remediation.
                </p>
              </div>
              <p></p>
            </div>
            <div className="single-feature mb-4">
              <div className="icon-box-wrap mb-2">
                <div className="mr-3 icon-box">
                  <img
                    src="assets/img/image-icon-3.png"
                    alt="icon "
                    className="img-fluid"
                  />
                </div>
                <p className="mb-0">
                  Whether installing a system or upgrading an existing one,
                  REGERA ensures a seamless integration process from start to
                  finish. PLCs are critical for your ability to control and
                  monitor machinery in industrial settings, and their automation
                  capabilities help to ensure accuracy, reliability, and
                  improved productivity.
                </p>
              </div>
              <p></p>
            </div>
            <div className="single-feature mb-4">
              <div className="icon-box-wrap mb-2">
                <div className="mr-3 icon-box">
                  <img
                    src="assets/img/image-icon-4.png"
                    alt="icon "
                    className="img-fluid"
                  />
                </div>
                <p className={`mb-0`}>
                  Manufacturing companies gain greater control over industrial
                  processes when utilizing SCADA Solutions. Realize benefits
                  like informed decision-making, improved productivity levels,
                  and the ability to remain ahead of the technological curve
                  through the ability to monitor and process real-time data.
                </p>
              </div>
            </div> */}

            <div className="container">
              <div className="" data-aos="fade-right">
                <Link to="/" className="insight-link">
                  <div className="row g-0">
                    <div className="col-lg-6 p-0">
                      <div className="insight-img-holder h-270">
                        <img
                          src="assets/img/1.jpg"
                          alt="insight1"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 p-0">
                      <div className="content-holder p-4 h-270">
                        <p className="text-white opacity-50 text-left d-block mr-auto">
                          January 03, 2024
                        </p>
                        <h3 className="text-white">
                          IoT Solutions: Benefits and use cases of custom
                          software
                        </h3>
                        <p className="text-white">
                          Learn from real-life examples how businesses use our
                          custom IoT solutions to drive productivity and derive
                          value from more excellent connectivity
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="row g-0">
                <div
                  className="col-lg-4 p-0"
                  data-aos="fade-right"
                  data-aos-delay="500"
                >
                  <Link to="/" className="insight-link">
                    <div className="bg-gray p-4 h-270">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="text-ash font-weight-bold">
                          Dec 20, 2023
                        </div>
                      </div>
                      <h4 className="text-dark">
                        AI in Business: New solutions and main challenges
                      </h4>
                      <p className="text-dark fs-14">
                        We explore how businesses use AI with real-world
                        examples of our custom solutions to overcome challenges
                        and drive profitability.
                      </p>
                    </div>
                    <div className="insight-img-holder h-270">
                      <Link to="/" className="insight-link">
                        <img
                          src="assets/img/3.jpg"
                          alt="insight1"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </Link>
                </div>

                <div
                  className="col-lg-4 p-0"
                  data-aos="fade-right"
                  data-aos-delay="400"
                >
                  <Link to="/" className="insight-link">
                    <div className="insight-img-holder h-270">
                      <Link to="/" className="insight-link">
                        <img
                          src="assets/img/2.jpg"
                          alt="insight1"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="bg-white p-4 h-270">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="text-ash font-weight-bold">
                          Dec 27, 2023
                        </div>
                      </div>
                      <h4 className="text-dark">
                        ERP Packages: Use cases for custom software and
                        challenges in design
                      </h4>
                      <p className="text-dark fs-14">
                        Our real-life case study reveals many benefits and
                        difficulties an organization can experience when
                        creating and implementing custom enterprise resource
                        planning software.
                      </p>
                    </div>
                  </Link>
                </div>

                <div
                  className="col-lg-4 p-0"
                  data-aos="fade-right"
                  data-aos-delay="200"
                >
                  <Link to="/" className="insight-link">
                    <div className="bg-gray p-4 h-270">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="text-ash font-weight-bold">
                          Jan 12, 2024
                        </div>
                      </div>
                      <h4 className="text-dark">
                        Embedded Systems: Custom solutions, platforms, and
                        implementation challenges
                      </h4>
                      <p className="text-dark fs-14">
                        Find out how our custom embedded systems solutions using
                        architectures tailor-made to different devices help
                        businesses better serve users through our real-life case
                        studies.
                      </p>
                    </div>
                    <div className="insight-img-holder h-270">
                      <Link to="/" className="insight-link">
                        <img
                          src="assets/img/4.jpg"
                          alt="insight1"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </Link>
                </div>
              </div>

              <div data-aos="fade-right">
                <Link to="/" className="insight-link">
                  <div className="row g-0">
                    <div className="col-lg-6 p-0">
                      <div className="content-holder p-4 h-270">
                        <h4 className="text-white">
                          Custom SCADA Solutions: Facing challenges to
                          streamlined productionases, real-life examples,
                          technologies & challenges
                        </h4>
                        <p className="text-white">
                          Explore through our real-life case studies how our
                          custom supervisory control and data acquisition
                          solutions enable businesses to manage production
                          facilities better.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 p-0">
                      <div className="insight-img-holder h-270">
                        <img
                          src="assets/img/5.jpg"
                          alt="insight1"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          {/* <div className={styles.insight_right}>
            {" "}
            <img src="assets/img/image-14.jpg" alt="about us" className="" />
          </div> */}
        </div>
      </section>
    </>
  );
}
