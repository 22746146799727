import React, { useEffect } from "react";
import AppRoutes from "./routes/Routes";
import Aos from "aos";
import 'aos/dist/aos.css';

const App = () => {
  useEffect(() => {
    Aos.init({
      duration: 1500,
      // disable "once" for easier debug
      once: false,
      // disable "mirror" for easier debug
      mirror: true,
      extend: "scroll",
    });
  }, []);

  // scroll to top on reload
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  return (
    <div>
      <AppRoutes />
    </div>
  );
};

export default App;
