import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import styles from "./Promo.module.css";

export default function VideoPromoTwo() {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <div className="overflow-hidden">
        <section
          // id="download"
          className={`video-promo ptb-100  ${styles.video_main}`}
          style={{
            background:
              "url('assets/img/video-bg.jpg') no-repeat center center / cover",
          }}
        >
          <div className="scale-down">
            <div className="container">
              <div className="row justify-content-center ">
                <div className="col-md-6">
                  <div className={`video-promo-content mt-4 text-center`}>
                    <Link
                      to="#"
                      className="popup-youtube bg-transparent border-0 video-play-icon d-inline-block"
                    >
                      {/* <span
                        className="ti-control-play"
                        onClick={() => setOpen(true)}
                      ></span>{" "} */}
                    </Link>
                    {/* <h5 className="mt-4 text-white">Watch video</h5> */}

                    {/*<div className="download-btn mt-5">
                  <a href="#/" className="btn google-play-btn mr-3">
                    <span className="ti-android"></span> Google Play
                  </a>
                  <a href="#/" className="btn app-store-btn">
                    <span className="ti-apple"></span> App Store
                  </a>
                </div>*/}
                  </div>
                </div>
              </div>
              <ModalVideo
                channel="youtube"
                youtube={{ mute: 1, autoplay: 1 }}
                isOpen={isOpen}
                videoId="QyhwSYhX09s"
                onClose={() => setOpen(false)}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
