import React, { useEffect, useState } from "react";
import styles from "./Navbar.module.css";
// import { Link } from "react-router-dom";

const Navbar = ({ darkBg, classOption }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header className={`header ${classOption} ${styles.main_nav_container}`}>
        <nav
          className={` navbar-expand-lg fixed-top ${styles.main_navbar}  ${
            darkBg ? "bg-transparent" : "custom-nav white-bg"
          } ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className={`container ${styles.main_container}`}>
            <div className={styles.navbar_toggler}>
              <a href="#home" className="navbar-brand ">
                {darkBg ? (
                  <>
                    <img
                      src="assets/img/logo-white.png"
                      width="150"
                      alt="logo"
                      className="img-fluid logo-white"
                    />
                    <img
                      src="assets/img/logo-black.png"
                      width="150"
                      alt="logo"
                      className="img-fluid logo-black"
                    />
                  </>
                ) : (
                  <img
                    src="assets/img/logo-color-1x.png"
                    width="120"
                    alt="logo"
                    className="img-fluid"
                    style={{ backgroundColor: "white", height: "200px" }}
                  />
                )}
              </a>

              <button
                className="navbar-toggler"
                type="button"
                // data-toggle="collapse"
                // data-target="#navbarSupportedContent"
                // aria-controls="navbarSupportedContent"
                // aria-expanded="false"
                // aria-label="Toggle navigation"
                onClick={() => {
                  document
                    .querySelector(".navbar-collapse")
                    .classList.toggle("active");
                }}
              >
                <span className="ti-menu"></span>
              </button>
            </div>

            <div
              className="collapse navbar-collapse "
              id="navbarSupportedContent"
            >
              <ul className={`navbar-nav  ${styles.main_navbar}`}>
                {/*             <li className="nav-item dropdown">
                   <Link
                    to="/"
                    className="nav-link page-scroll dropdown-toggle"
                    href="#"
                    id="navbarDropdownHome"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Home
                  </Link>*/}
                <li className="nav-item">
                  <a className="nav-link page-scroll nav-logo" href="#home">
                    <img src="assets/img/logo-white.png" alt="logo" />
                  </a>
                  <button
                    className="menu-closer btn font-bold"
                    onClick={() => {
                      document
                        .querySelector(".navbar-collapse")
                        .classList.remove("active");
                    }}
                  >
                    <span className="ti-close"></span>
                  </button>
                  <a
                    className="nav-link page-scroll"
                    href="#home"
                    onClick={() => {
                      document
                        .querySelector(".navbar-collapse")
                        .classList.toggle("active");
                    }}
                  >
                    Home
                  </a>
                  {/*                  <div
                    className="dropdown-menu submenu"
                    aria-labelledby="navbarDropdownHome"
                  >
                    <Link to="/" className="dropdown-item">
                      Demo Template 1
                    </Link>
                    <Link to="/home-two" className="dropdown-item">
                      Demo Template 2
                    </Link>
                    <Link to="/home-three" className="dropdown-item">
                      Demo Template 3
                    </Link>
                    <Link to="/home-four" className="dropdown-item">
                      Demo Template 4
                    </Link>
                    <Link to="/home-five" className="dropdown-item">
                      Demo Template 5
                    </Link>
                    <Link to="/home-six" className="dropdown-item">
                      Demo Template 6
                    </Link>
                    <Link to="/home-seven" className="dropdown-item">
                      Demo Template 7
                    </Link>
                    <Link to="/home-eight" className="dropdown-item">
                      Demo Template 8{" "}
                      <span className="badge badge-warning">New</span>
                    </Link>
                  </div>*/}
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link page-scroll"
                    href=" #solutions"
                    onClick={() => {
                      document
                        .querySelector(".navbar-collapse")
                        .classList.toggle("active");
                    }}
                  >
                    Solutions
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link page-scroll"
                    href="#services"
                    onClick={() => {
                      document
                        .querySelector(".navbar-collapse")
                        .classList.toggle("active");
                    }}
                  >
                    Services
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link page-scroll"
                    href="#technologies"
                    onClick={() => {
                      document
                        .querySelector(".navbar-collapse")
                        .classList.toggle("active");
                    }}
                  >
                    Technologies
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link page-scroll"
                    href=" #insights"
                    onClick={() => {
                      document
                        .querySelector(".navbar-collapse")
                        .classList.toggle("active");
                    }}
                  >
                    Insights
                  </a>
                </li>
                {/* <li className="nav-item dropdown">
                  <a
                    className="nav-link page-scroll dropdown-toggle"
                    href="#/"
                    id="navbarDropdownPage"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Pages
                  </a>
                  <div
                    className="dropdown-menu submenu"
                    aria-labelledby="navbarDropdownPage"
                  >
                    <Link className="dropdown-item" to="/login">
                      Login Page 1
                    </Link>
                    <Link className="dropdown-item" to="/login-two">
                      Login Page 2
                    </Link>
                    <Link className="dropdown-item" to="/basic-signup">
                      Signup Page 1
                    </Link>
                    <Link className="dropdown-item" to="/signup-two">
                      Signup Page 2
                    </Link>
                    <Link className="dropdown-item" to="/reset-password">
                      Password Reset
                    </Link>
                    <Link className="dropdown-item" to="/change-password">
                      Change Password
                    </Link>
                    <Link className="dropdown-item" to="/download">
                      Download Page
                    </Link>
                    <Link className="dropdown-item" to="/review">
                      Review Page
                    </Link>
                    <Link className="dropdown-item" to="/faq">
                      FAQ Page
                    </Link>
                    <Link to="*" className="dropdown-item">
                      404 Page
                    </Link>
                    <Link className="dropdown-item" to="/coming-soon">
                      Coming Soon
                    </Link>
                    <Link className="dropdown-item" to="/thank-you">
                      Thank You
                    </Link>
                    <Link className="dropdown-item" to="/our-team">
                      Team Page
                      <span className="badge badge-danger badge-pill ml-2">
                        New
                      </span>
                    </Link>
                    <Link className="dropdown-item" to="/team-details">
                      Team Single
                      <span className="badge badge-danger badge-pill ml-2">
                        New
                      </span>
                    </Link>
                  </div>
                </li>*/}

                {/* TBD
                <li className="nav-item dropdown">
                  <Link
                    className="nav-link page-scroll dropdown-toggle"
                    to="/blog-grid"
                    id="navbarBlogPage"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Blog
                    <span className="custom-nav-badge badge badge-danger badge-pill">
                      New
                    </span>
                  </Link>

                  <div
                    className="dropdown-menu submenu"
                    aria-labelledby="navbarBlogPage"
                  >
                    <Link className="dropdown-item" to="/blog-grid">
                      Blog Grid
                    </Link>

                    */}

                {/*  <Link className="dropdown-item" to="/blog-sidebar">
                      Blog Left Sidebar
                    </Link>
                    <Link className="dropdown-item" to="/blog-details">
                      Details Right Sidebar
                    </Link>
                  </div> 
                </li> */}

                {/*<li className="nav-item">
                  <a className="nav-link page-scroll" href="#team">
                    Team
                  </a>
                </li>*/}

                <li className="nav-item">
                  <a
                    className="nav-link page-scroll"
                    href="#contact"
                    onClick={() => {
                      document
                        .querySelector(".navbar-collapse")
                        .classList.toggle("active");
                    }}
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
