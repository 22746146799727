import AppServices from "../../components/app-services";
import styles from "./index-4.module.css";
import React from "react";

export default function HeroFour() {
  return (
    <>
      <div className="overflow-hidden">

        <section
          id="home"
          className={`video-promo ptb-100 h-screen position-relative`}
          style={{
            background:
              "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)),url('assets/img/hero-new.png') no-repeat center center / cover",
          }}
        >
          <video
            playsInline="playsinline"
            autoPlay="autoplay"
            className="video-hero"
            muted="muted"
            loop="loop"
          >
            <source src="assets/videos/hero.mp4" type="video/mp4" />
          </video>
          <div className="video-overlay"></div>
          <div
            className={`${styles.home_section} position-relative hero-content hero-text`}
          >
            <h1 className="mt-4 hero-title text-white scale-down">
              Empowering Growth with Custom Software Development
            </h1>
          </div>
        </section>
      </div>


      {/* <div className="container">
        <div className="">
          <div className={styles.home_main}>
            <div className={`d-flex mob-block mt-5 justify-content-between align-items-start gap-5`}>
              {" "}
              <h4 style={{minWidth: "240px"}} className="title font-weight-bold mt-2">Application Services</h4>
              <p className="lead hero-text mobile-center">
                Specializing in custom software development, we help
                manufacturing, engineering, and commercial businesses align
                business systems with their goals and objectives while achieving
                synergy across operational processes. With the ability to design
                and implement any size application or solution, we develop
                everything from user interfaces to distributed database
                applications. Our expertise includes n-tier-based distributed
                software architecture and implementation and a wide range of
                services, including ERP solutions, IIoT, MQTT, web-based
                applications, AI, embedded systems, and more.
              </p>
            </div>
          </div>

          <div className={styles.home_bottom}>
            <div className="single-promo hero-card single-promo-hover single-promo-1 rounded text-center white-bg p-4 h-100 bounce">
              <div className="circle-icon mb-5">
                <span className="ti-desktop text-white"></span>
              </div>
              <h5> Solution Consulting</h5>
              <p className="mb-0">
                We offer guidance in software solution consultancy to ensure you
                select the appropriate software (like CRM, ERP, DMS, CMS, etc.)
                for your specific needs, saving you time and money.
              </p>
            </div>
            <div className="single-promo hero-card delay-1 single-promo-hover single-promo-1 rounded text-center white-bg p-4 h-100 bounce">
              <div className="circle-icon mb-5">
                <span className="ti-layers-alt text-white"></span>
              </div>
              <h5> Application Development</h5>
              <p className="mb-0">
                Our tailor-made software solutions ensure flawless integration
                with existing infrastructures and systems, allowing your
                organization to adopt new technologies and systems seamlessly.
              </p>
            </div>
            <div
              className={`${styles.home_bottom_services_last} delay-2 hero-card single-promo single-promo-hover single-promo-1 bounce rounded text-center white-bg p-4 h-100`}
            >
              <div className="circle-icon mb-5">
                <span className="ti-headphone-alt text-white"></span>
              </div>
              <h5>Advanced Support</h5>
              <p className="mb-0">
                Our team of highly proficient architects and engineers work to
                tackle complex problems and delve into the core programming to
                fix disturbances and improve back-end operations.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <AppServices />
    </>
  );
}
