import { useForm } from "@formspree/react";
import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export default function Sidebar() {
  const [state, handleSubmit] = useForm("mvoezevb");

  if (state.succeeded) {
    // return <p>Thanks for joining!</p>;
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Subscribed successfully!",
      showConfirmButton: false,
      timer: 1500,
    });

    const form = document.getElementById("subscribeForm");
    form.reset();
  }
  return (
    <>
      <div className="col-lg-4 col-md-12">
        <div className="sidebar-left pr-4">
          {/* <aside className="widget widget-search">
            <form>
              <input
                className="form-control"
                type="search"
                placeholder="Type Search Words"
              />
              <button className="search-button" type="submit">
                <span className="ti-search"></span>
              </button>
            </form>
          </aside> */}

          {/* <aside className="widget widget-categories">
            <div className="widget-title">
              <h6>Categories</h6>
            </div>
            <ul>
              <li>
                <a href="#/">
                  Journey <span className="float-right">112</span>
                </a>
              </li>
              <li>
                <a href="#/">
                  Development <span className="float-right">86</span>
                </a>
              </li>
              <li>
                <a href="#/">
                  Sport <span className="float-right">10</span>
                </a>
              </li>
              <li>
                <a href="#/">
                  Photography <span className="float-right">144</span>
                </a>
              </li>
              <li>
                <a href="#/">
                  Symphony <span className="float-right">18</span>
                </a>
              </li>
            </ul>
          </aside> */}

          <aside className="widget widget-recent-entries-custom">
            <div className="widget-title" data-aos="fade-left">
              <h6>Recent Posts</h6>
            </div>
            <ul data-aos="fade-left">
              <li className="clearfix">
                <div className="wi">
                  <Link to="/blog-details">
                    <img
                      src="assets/img/blog/iot.jpeg"
                      alt="recent post"
                      className="img-fluid rounded"
                    />
                  </Link>
                </div>
                <div className="wb">
                  <Link to="/blog-details">
                    How IoT is Changing the Business Landscape.
                  </Link>
                  <span className="post-date">May 8, 2023</span>
                </div>
              </li>
              <li className="clearfix">
                <div className="wi">
                  <Link to="/blog-details1">
                    <img
                      src="assets/img/blog/2.jpg"
                      alt="recent post"
                      className="img-fluid rounded"
                    />
                  </Link>
                </div>
                <div className="wb">
                  <Link to="/blog-details1">
                    How Switching to the Cloud Leads to Long-Term Cost
                    Effectiveness.
                  </Link>
                  <span className="post-date">May 8, 2023</span>
                </div>
              </li>
              <li className="clearfix">
                <div className="wi">
                  <Link to="/blog-details2">
                    <img
                      src="assets/img/blog/3.jpg"
                      alt="recent post"
                      className="img-fluid rounded"
                    />
                  </Link>
                </div>
                <div className="wb">
                  <Link to="/blog-details2">
                    Developing a Quality ERP System for Your Business.
                  </Link>
                  <span className="post-date">May 8, 2023</span>
                </div>
              </li>
            </ul>
          </aside>

          <aside className="widget widget-categories">
            <div className="widget-title">
              <h6>Newsletter</h6>
            </div>
            <p>Enter your email address below to subscribe to my newsletter</p>
            <form
              id="subscribeForm"
              onSubmit={handleSubmit}
              method="post"
              className="d-block d-md-block d-lg-block"
            >
              <input
                type="text"
                className="form-control input"
                id="email-footer"
                name="email"
                placeholder="info@regera.tech"
              />
              <button
                type="submit"
                className="btn solid-btn btn-block btn-not-rounded mt-3"
              >
                Subscribe
              </button>
            </form>
          </aside>

          {/* <aside className="widget widget-tag-cloud">
            <div className="widget-title">
              <h6>Tags</h6>
            </div>
            <div className="tag-cloud">
              <a href="#//">e-commerce</a>
              <a href="#//">portfolio</a>
              <a href="#//">responsive</a>
              <a href="#//">bootstrap</a>
              <a href="#//">business</a>
              <a href="#//">corporate</a>
            </div>
          </aside> */}
        </div>
      </div>
    </>
  );
}
