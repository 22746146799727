import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./Navbar.module.css";

const NavBar1 = ({ darkBg, classOption }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <div>
      {" "}
      <>
        <header className={`header ${classOption}`}>
          <nav
            className={`navbar navbar-expand-lg fixed-top ${
              darkBg ? "bg-transparent" : "custom-nav white-bg"
            } ${scroll > headerTop ? "affix" : ""}`}
          >
            <div className="container">
              <Link to="/">
                {darkBg ? (
                  <>
                    <img
                      src="assets/img/logo-black.png"
                      width="120"
                      alt="logo"
                      className="img-fluid logo-black"
                    />
                    <img
                      src="assets/img/logo-white.png"
                      width="120"
                      alt="logo"
                      className="img-fluid logo-white"
                    />
                  </>
                ) : (
                  <img
                    src="assets/img/logo-black.png"
                    width="120"
                    alt="logo"
                    className="img-fluid"
                  />
                )}
              </Link>

              <div
                className="collapse blog-nav navbar-collapse main-menu"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  {/*             <li className="nav-item dropdown">
                 <Link
                  to="/"
                  className="nav-link page-scroll dropdown-toggle"
                  href="#"
                  id="navbarDropdownHome"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Home
                </Link>*/}
                  <Link className="text-white" to="/">
                    <li className={styles.li_back}>
                      <ArrowBackIcon class="blog-back" />
                      <span className="text-white blog-back">Back</span>
                    </li>
                  </Link>
                  {/* <li className="nav-item">
                    <Link to="/solution">Solutions</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/services">Services</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/technologies">Technologies</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/about">Insight</Link>
                  </li>
                
                   <li className="nav-item">
                    <Link to='/contact' >Contact</Link>
                  </li> */}
                </ul>
              </div>

              <Link className="text-white blog-back-btn" to="/">
                <ArrowBackIcon class="blog-back" />
                <span className="text-white blog-back">Back</span>
              </Link>
            </div>
          </nav>
        </header>
      </>
    </div>
  );
};

export default NavBar1;
