import React from "react";
import styles from "./PageHeader.module.css";
export default function PageHeader({ PageTitle, HeaderTitle, img }) {
  return (
    <>
      <section
        className={`hero-section ${styles.header_img} `}
        style={{
          background: ` url(${img})no-repeat center center / cover`,
        }}
      >
        {" "}
        <div className={`container  ${styles.header_h1}`}>
          <h1 className="text-white mb-0">{HeaderTitle}</h1>
        </div>
      </section>
    </>
  );
}
