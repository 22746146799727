import styles from "./app.module.css";
import React from "react";

export default function AppServices({ bgColor, light }) {
    return (
        <>
            <section id="blog" className={`our-blog-section ${bgColor}`}>
                <div className={`container ${styles.news}`}>
                    <div className="row">
                        <div className={window.innerWidth <= 1024 ? "" : "col-md-12"}>
                            <div className={`section-heading mb-5 `}>
                                <h2 className={`text-dark`} data-aos="fade-right">
                                    Application Services
                                </h2>
                                <p className="lead" data-aos="fade-right" data-aos-delay="100">
                                    Specializing in custom software development, we help
                                    manufacturing, engineering, and commercial businesses align
                                    business systems with their goals and objectives while achieving
                                    synergy across operational processes. With the ability to design
                                    and implement any size application or solution, we develop
                                    everything from user interfaces to distributed database
                                    applications. Our expertise includes n-tier-based distributed
                                    software architecture and implementation and a wide range of
                                    services, including ERP solutions, IIoT, MQTT, web-based
                                    applications, AI, embedded systems, and more.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`row ${styles.new_h3}`}>
                        <div className={window.innerWidth <= 1024 ? "" : "col-md-4"} data-aos="fade-right" data-aos-delay="600">
                            <div className="single-blog-card card border-0 shadow-sm">
                                {/* <span className="category position-absolute badge badge-pill badge-primary">
                  IOT
                </span> */}
                                <div className="blog-cover-img">
                                    <img
                                        src="assets/img/blog/n1.jpg"
                                        className="card-img-top position-relative"
                                        alt="blog"
                                    />
                                </div>
                                <div className="card-body">
                                    {/* <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">Jan 21, 2019</li>
                      <li className="list-inline-item">
                        <span>45</span> Comments
                      </li>
                      <li className="list-inline-item">
                        <span>10</span> Share
                      </li>
                    </ul>
                  </div> */}
                                    <h3 className=" card-title">
                                    Solution Consulting
                                    </h3>
                                    <p className="card-text text-dark">
                                    We offer guidance in software solution consultancy to ensure you
                select the appropriate software (like CRM, ERP, DMS, CMS, etc.)
                for your specific needs, saving you time and money.
                                    </p>
                                    {/* <Link to="/blog-details" className="detail-link">
                    Read more <span className="ti-arrow-right"></span>
                  </Link> */}
                                </div>
                            </div>
                        </div>
                        <div className={window.innerWidth <= 1024 ? "" : "col-md-4"} data-aos="fade-right" data-aos-delay="400">
                            <div className="single-blog-card card border-0 shadow-sm">
                                {/* <span className="category position-absolute badge badge-pill badge-danger">
                  Cloud
                </span> */}
                                <div className="blog-cover-img">
                                    <img
                                        src="assets/img/blog/n2.jpg"
                                        className="card-img-top position-relative"
                                        alt="blog"
                                    />
                                </div>

                                <div className={`card-body `}>
                                    {/* <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">May 26, 2019</li>
                      <li className="list-inline-item">
                        <span>30</span> Comments
                      </li>
                      <li className="list-inline-item">
                        <span>5</span> Share
                      </li>
                    </ul>
                  </div> */}
                                    <h3 className=" card-title">
                                    Application Development
                                    </h3>
                                    <p className="card-text text-dark">
                                    Our tailor-made software solutions ensure flawless integration
                with existing infrastructures and systems, allowing your
                organization to adopt new technologies and systems seamlessly.
                                    </p>
                                    {/* <Link to="/blog-details1" className="detail-link">
                    Read more <span className="ti-arrow-right"></span>
                  </Link> */}
                                </div>
                            </div>
                        </div>
                        <div className={window.innerWidth <= 1024 ? "" : "col-md-4"} data-aos="fade-right" data-aos-delay="200">
                            <div className="single-blog-card card border-0 shadow-sm">
                                {/* <span className="category position-absolute badge badge-pill badge-info">
                  ERP
                </span> */}
                                <div className="blog-cover-img">
                                    <img
                                        src="assets/img/blog/n3.jpg"
                                        className="card-img-top"
                                        alt="blog"
                                    />
                                </div>

                                <div className="card-body">
                                    {/* <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">Apr 25, 2019</li>
                      <li className="list-inline-item">
                        <span>41</span> Comments
                      </li>
                      <li className="list-inline-item">
                        <span>30</span> Share
                      </li>
                    </ul>
                  </div> */}
                                    <h3 className=" card-title">
                                    Advanced Support
                                    </h3>
                                    <p className="card-text text-dark">
                                    Our team of highly proficient architects and engineers work to
                tackle complex problems and delve into the core programming to
                fix disturbances and improve back-end operations.
                                    </p>
                                    {/* <Link to="/blog-details2" className="detail-link">
                    Read more <span className="ti-arrow-right"></span>
                  </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
