import styles from "./app.module.css";
import React from "react";

export default function Expertise({ bgColor, light }) {
    return (
        <>
            <section id="blog" className={`our-blog-section ${bgColor}`}>
                <div className={`container ${styles.news}`}>
                    <div className="row">
                        <div className={window.innerWidth <= 1024 ? "" : "col-md-12"}>
                            <div className={`section-heading mb-5 `}>
                                <h2 className={`text-dark`} data-aos="fade-right">
                                    Expertise in Custom Software Development
                                </h2>
                                <p className="lead" data-aos="fade-right" data-aos-delay="100">
                                REGERA specializes in custom software development, architecture, object-oriented analysis, design, and implementation. Our experts are well-versed in cutting-edge programmatic languages and software like Javascript, Python, SQL, ASP, Web APIs, and more.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`row ${styles.new_h3}`}>
                        <div className={window.innerWidth <= 1024 ? "" : "col-md-4"} data-aos="fade-right" data-aos-delay="600">
                            <div className="single-blog-card card border-0 shadow-sm">
                                {/* <span className="category position-absolute badge badge-pill badge-primary">
                  IOT
                </span> */}
                                <div className="blog-cover-img">
                                    <img
                                        src="assets/img/blog/d1.jpg"
                                        className="card-img-top position-relative"
                                        alt="blog"
                                    />
                                </div>
                                <div className="card-body">
                                    {/* <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">Jan 21, 2019</li>
                      <li className="list-inline-item">
                        <span>45</span> Comments
                      </li>
                      <li className="list-inline-item">
                        <span>10</span> Share
                      </li>
                    </ul>
                  </div> */}
                                    <h3 className=" card-title">
                                    Straightforward Design
                                    </h3>
                                    <p className="card-text text-dark">
                                    Quickly parse data and easily leverage your custom software’s capabilities while enjoying a sleek and straightforward design.
                                    </p>
                                    {/* <Link to="/blog-details" className="detail-link">
                    Read more <span className="ti-arrow-right"></span>
                  </Link> */}
                                </div>
                            </div>
                        </div>
                        <div className={window.innerWidth <= 1024 ? "" : "col-md-4"} data-aos="fade-right" data-aos-delay="400">
                            <div className="single-blog-card card border-0 shadow-sm">
                                {/* <span className="category position-absolute badge badge-pill badge-danger">
                  Cloud
                </span> */}
                                <div className="blog-cover-img">
                                    <img
                                        src="assets/img/blog/d2.jpg"
                                        className="card-img-top position-relative"
                                        alt="blog"
                                    />
                                </div>

                                <div className={`card-body `}>
                                    {/* <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">May 26, 2019</li>
                      <li className="list-inline-item">
                        <span>30</span> Comments
                      </li>
                      <li className="list-inline-item">
                        <span>5</span> Share
                      </li>
                    </ul>
                  </div> */}
                                    <h3 className=" card-title">
                                    Intuitive User Experience
                                    </h3>
                                    <p className="card-text text-dark">
                                    An intuitive user interface can make or break the user experience, so we include easy-to-use features that accommodate all technological skill levels.
                                    </p>
                                    {/* <Link to="/blog-details1" className="detail-link">
                    Read more <span className="ti-arrow-right"></span>
                  </Link> */}
                                </div>
                            </div>
                        </div>
                        <div className={window.innerWidth <= 1024 ? "" : "col-md-4"} data-aos="fade-right" data-aos-delay="200">
                            <div className="single-blog-card card border-0 shadow-sm">
                                {/* <span className="category position-absolute badge badge-pill badge-info">
                  ERP
                </span> */}
                                <div className="blog-cover-img">
                                    <img
                                        src="assets/img/blog/d3.jpg"
                                        className="card-img-top"
                                        alt="blog"
                                    />
                                </div>

                                <div className="card-body">
                                    {/* <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">Apr 25, 2019</li>
                      <li className="list-inline-item">
                        <span>41</span> Comments
                      </li>
                      <li className="list-inline-item">
                        <span>30</span> Share
                      </li>
                    </ul>
                  </div> */}
                                    <h3 className=" card-title">
                                    Accuracy
                                    </h3>
                                    <p className="card-text text-dark">
                                    Leverage REGERA's business automation capabilities for greater data accuracy and an improved bottom line.
                                    </p>
                                    {/* <Link to="/blog-details2" className="detail-link">
                    Read more <span className="ti-arrow-right"></span>
                  </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
