import React from "react";
// import SingleBlog from "../components/blogs/SingleBlog";
import Footer from "../components/layout/footer/Footer";
import NavBar1 from "../components/layout/header/NavBar1";
import Layout from "../components/layout/Layout";

import styles from "./BlogDetails.module.css";
import Loader from "../components/loader";
import OdooDetails from "../components/career/OdooDetails";

export default function Careers2() {
  const [loader, setLoader] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 5000);
  }, []);
  return (
    <Layout>
      {loader && <Loader />}
      <NavBar1 darkBg />
      <div className="overflow-hidden">
        <section
          className={`video-promo ptb-100 blog-hero `}
          id="blog-header"
          style={{
            background:
              "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url('/assets/img/career.jpg') no-repeat center center / cover",
          }}
        >
          <div className={styles.home_section}>
            <h1 className="mt-4 text-white scale-down">Join Our Team</h1>
          </div>
        </section>
      </div>

      <OdooDetails />
      <Footer />
    </Layout>
  );
}
