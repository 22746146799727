import React from "react";
import styles from "./Footer.module.css";
import { FaLinkedinIn, FaXTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

export default function Footer({ space }) {
  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top background-img-2  ${space ? "pt-150" : "pt-60"
            }`}
          style={{
            background:
              "url('assets/img/footer-bg.png') no-repeat center top / cover",
          }}
        >
          <div className={`container ${styles.footer_main}`}>
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                <div className="footer-nav-wrap text-white pt-5">
                  <div className={styles.footer_logo}>
                    <img
                      src="assets/img/logo-black.png"
                      alt="footer logo"
                      width="120"
                      className="img-fluid mb-3"
                    />
                  </div>
                  <p>
                    REGERA helps businesses achieve operational success
                    through our comprehensive range of cross-platform
                    technologies.
                  </p>

                  <div className="social-list-wrap">
                    <ul className="social-list list-inline list-unstyled">
                      <li className="list-inline-item">
                        <a href="#/" target="_blank" title="Facebook">
                          <span className="ti-facebook"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#/" target="_blank" title="Twitter">
                          {/* <span className="ti-twitter-x"></span> */}
                          <FaXTwitter />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#/" target="_blank" title="Instagram">
                          <span className="ti-instagram"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#/" target="_blank" title="printerst">
                          {/* <span className="ti-pinterest"></span> */}
                          <FaLinkedinIn />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-8 pt-5">
                <div className={`row `}>
                  {/* <div className="col-sm-6 col-md-4 col-lg-3 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white ">
                      <h5 className="mb-3 text-white">Resources</h5>
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <a href="#/">Help</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Events</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Live Support</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Open Sources</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Documentation</a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Company</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2">
                          {/* <a href="#about">Careers</a> */}
                          <Link to="/careers">Careers</Link>
                        </li>
                        <li className="mb-2">
                          <a href="./#solutions">Solutions</a>
                        </li>
                        <li className="mb-2">
                          <a href="./#services">Services</a>
                        </li>
                        <li className="mb-2">
                          <a href="./#technologies">Technologies</a>
                        </li>
                        {/* <li className="mb-2">
                          <a href="#contact">Contact</a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Location</h5>
                      <ul className="list-unstyled support-list">
                        {/* <li className="mb-2 d-flex align-items-center">
                          <span className="ti-location-pin mr-2"></span>
                          100 West Big Beaver Road,
                          <br />
                          Troy, Michigan 48084
                        </li> */}
                        {/* <li className="mb-2 d-flex align-items-center">
                          <span className="ti-location-pin mr-2"></span>
                          Circle Mall, Level 2,
                          <br />
                          Jumeirah Village Circle,
                          <br />
                          Dubai, UAE
                        </li> */}
                        {/* <li className="mb-2 d-flex align-items-center">
                          <span className="ti-mobile mr-2"></span>
                          <a href="tel:+61283766284"> +61 2 8376 6284</a>
                        </li> */}
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-email mr-2"></span>
                          <a href="mailto:info@regera.tech">
                            {" "}
                            info@regera.tech
                          </a>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-world mr-2"></span>
                          <a href="#/"> www.regera.tech</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom border-gray-light mt-5 py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                  <div className="copyright-wrap small-text">
                    <p className="mb-0 text-white">
                      © REGERA, All rights reserved
                    </p>
                  </div>
                </div>
                <div className={`col-md-6 col-lg-5 ${styles.footer_bottom}`}>
                  <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a className="small-text" href="#/">
                          Terms
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="small-text" href="#/">
                          Security
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a className="small-text" href="#/">
                          Privacy Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
