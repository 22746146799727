// import styles from "./Prices.module.css";
import React from "react";
// import { useState } from "react";
import { FaChevronDown } from "react-icons/fa";

export default function Price({ hasBg }) {
  // const [showFullContent, setShowFullContent] = useState(false);

  // const devOpsContent = `
  // As a leader in efficiency and seamless operations, our DevOps
  // services give you the power to deliver software quickly and
  // effectively using a collaborative approach to optimization. Our
  // focus lies in the implementation of better philosophies,
  // strategies, and protocols to drive success forward.
  // `;

  // const truncatedContent = showFullContent
  //   ? devOpsContent
  //   : devOpsContent.slice(0, 150) + "...";
  const [activeAcc, setActiveAcc] = React.useState(1)

  const accData = [
    {
      id: 1,
      title: "Software Engineering",
      content: "Transform your business with our software engineering expertise. We specialize in seamless solutions that integrate with your operations, unlocking new possibilities. Our skilled engineers deliver innovative, reliable, and efficient software solutions. Partner with us to stay ahead in today's competitive market."
    },
    {
      id: 2,
      title: "Application Services",
      content: "Creating faster, more efficient operations is key to long-term scalability for your business. Together, we’ll work with you to select industry-leading software applications designed to take your processes to the next level of speed."
    },
    {
      id: 3,
      title: "IT Consulting",
      content: "We pride ourselves on creating bespoke IT solutions unique to your organization's needs. Through our IT consulting services, we’ll analyze your current systems, develop powerful IT strategies, and build an actionable plan for streamlined implementation."
    },
    {
      id: 4,
      title: "Managed IT Services",
      content: "Managing the IT needs of your organization can feel overwhelming. Our managed IT services are here to alleviate stress from your in-house IT department and provide expert handling of your most complex IT tasks, so you can focus on running your business."
    },
    {
      id: 5,
      title: "DevOps",
      content: "As a leader in efficiency and seamless operations, our DevOps services give you the power to deliver software quickly and effectively using a collaborative approach to optimization. Our focus lies in the implementation of better philosophies, strategies, and protocols to drive success forward."
    },
    {
      id: 6,
      title: "Smart Teams",
      content: "All-star teams don’t happen overnight. Our smart team services were carefully crafted to transform your in-house teams into a powerful force, leveraging proven methodologies to align their vision, eliminate miscommunication, and keep you moving forward."
    },
    {
      id: 7,
      title: "Factory Automation",
      content: "Revolutionize production with our Factory Automation. Leverage tech, streamline processes, boost efficiency, integrate machinery seamlessly. Strategies optimize workflow, cut costs, keep you competitive. Embrace the future with us."
    },
    {
      id: 8,
      title: "Quality Assurance",
      content: "We take the guesswork out of ensuring your software products and other applications are up to par through a rigorous quality assurance and testing system crafted by our elite professionals. Ultimately, we give you the insight you need to address quality issues before they arise."
    },
    {
      id: 9,
      title: "Maintenance & Support",
      content: "Different levels of support available depending on your needs,Help with software upgrades and troubleshooting,Continuous performance monitoring of your, Various support tiers to cater to your specific requirements,Assistance with software updates, maintenance, and issue resolution."
    },
  ]
  return (
    <>
      <div className="overflow-hidden">
       <section className="services-section bg-gray-1" id="services">
          <div className="row g-0">
            <div className="col-md-6 bg-73 p-0 pt-85">
              <div className="w-75 ml-auto py-5 my-5 pr-5">
                <h3 className="text-white font-weight-bold mb-0" data-aos="fade-right" data-aos-duration="1000">
                  Our Services are Tailored to Meet Your Needs
                </h3>
              </div>
              <div className="w-100 h-100 overflow-hidden">
              <img src="/assets/img/service-left.webp" alt="line" className="p-img img-fluid w-100 h-100 object-fit-cover" />
              </div>
            </div>
            <div className="col-md-6 h-100 p-0">
              <div data-aos="fade-left" data-aos-duration="1000" className="custom-accordion h-100 pt-120 bg-gray-1 p-5 d-flex flex-column gap-2">
                {
                  accData.map((item, index) => {
                    return (
                      <div className={`accordion-item px-5 mx-5 ${item.id === activeAcc ? "active" : "border-bottom border-dark"}`} key={index}>
                        <div onClick={()=>{setActiveAcc(item.id)}} className={`accordion-header d-flex ${item.id === activeAcc ? "justify-content-start gap-4" : "justify-content-between"} align-items-center`}>
                          <h4 className="mb-0">
                            {item.title}
                          </h4>

                          {
                            item.id === activeAcc ? '' : <button className="btn border-bottom-0 sq d-flex justify-content-center align-items-center btn-outline-dark rounded-0 text-orange">
                              <FaChevronDown className="fs-24" />
                            </button>
                          }



                        </div>
                        <div className={`accordion-body py-4 ${item.id === activeAcc ? "d-block" : "d-none"}`}>
                          <p className="text-70 mb-0" style={{color: "#707070"}}>
                            {item.content}
                          </p>
                        </div>
                      </div>
                    )
                  })
                }
                
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
