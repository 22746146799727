import React from 'react'

const Loader = () => {
    return (
        <div className='loader'>
            <div className="container">
                <img src="assets/img/logo-white.png" className='loader-logo img-fluid' alt="" />
            </div>
        </div>
    )
}

export default Loader