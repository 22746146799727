import React from "react";
import styles from "./Faqs.module.css";

export default function FaqStatic({ Title, space }) {
  return (
    <>
      <section
        className={`bg-white ${styles.faq_main}  ${space ? "ptb-100" : "ptb-100"
          }`}
      >
        <div className="container">
          {Title ? (
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading mb-5 text-center">
                  <h2>Frequently Asked Questions</h2>
                  <p className="lead">
                    Quickly morph client-centric results through performance
                    based applications. Proactively facilitate professional
                    human capital for cutting-edge.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-md-6" data-aos="fade-up">
              <div className="single-faq">
                <h5>What’s included in your technology suite?</h5>
                <p>
                  We serve businesses of all sizes and scales, including SMBs,
                  enterprises, and startups. Thanks to our scalable and
                  adaptable solutions, our technology can grow alongside your
                  business and continue to drive success.
                </p>
              </div>
            </div>
            <div className="col-md-6" data-aos="fade-up">
              <div className="single-faq">
                <h5>What’s the user experience like?</h5>
                <p>
                  Creating an unparalleled user experience is top of mind when
                  designing our custom software applications and solutions.
                  Using intuitive interfaces and user-friendly features, we can
                  ensure total user accessibility regardless of technological
                  skill.
                </p>
              </div>
            </div>
            <div className="col-md-6" data-aos="fade-up">
              <div className="single-faq">
                {/* <h5>Why choose REGERA?</h5> */}
                <h5>Why choose REGERA?</h5>
                <p>
                  For businesses seeking improved efficiency, innovative
                  solutions, and enhanced business performance, REGERA offers
                  a comprehensive suite of custom software and business
                  automation technologies designed to meet their specific needs.
                </p>
              </div>
            </div>
            <div className="col-md-6" data-aos="fade-up">
              <div className="single-faq">
                <h5>
                  Can you tailor your technology to meet my business’s needs?
                </h5>
                <p>
                  Business software customization is our core service, and we
                  will work closely with you to devise a custom software
                  solution that accounts for all your business needs while
                  creating a seamless user experience.
                </p>
              </div>
            </div>

            <div className="col-md-6" data-aos="fade-up">
              <div className="single-faq">
                <h5>How’s your support?</h5>
                <p>
                  Our friendly and responsive support is at the heart of our
                  customers’ success. We offer 24/7 live phone support and
                  strive for timely resolutions and an exceptional customer
                  experience.
                </p>
              </div>
            </div>
            <div className="col-md-6" data-aos="fade-up">
              <div className="single-faq">
                <h5>Who owns the software once it’s made?</h5>
                <p>
                  You will own any custom software we write for you, from the source code to any executable files needed to run the program. You’ll be able to build, maintain, run, and add on to any custom software program we create for you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
