import React from "react";
import styles from "./Promo.module.css";

const Promo = () => {
  return (
    <>
      <section className="promo-section pb-100 " data-aos="fade-up">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              {/* <div
                className={`section-heading text-center mb-5 mt-5 ${styles.promo}`}
              >
                <h2 className="mobile-center">
                  Expertise in Custom Software Development
                </h2>
                <p className="lead mobile-center">
                  REGERA specializes in custom software development,
                  architecture, object-oriented analysis, design, and
                  implementation. Our experts are well-versed in cutting-edge
                  programmatic languages and software like Javascript, Python,
                  SQL, ASP, Web APIs, and more.
                </p>
              </div> */}
              <div
                className={`d-flex mob-block mt-5 justify-content-between align-items-start gap-5`}
              >
                {" "}
                <h4
                  style={{ minWidth: "340px" }}
                  className="title font-weight-bold mt-2"
                >
                  Expertise in Custom Software Development
                </h4>
                <p className="lead hero-text mobile-center">
                REGERA specializes in custom software development,
                  architecture, object-oriented analysis, design, and
                  implementation. Our experts are well-versed in cutting-edge
                  programmatic languages and software like Javascript, Python,
                  SQL, ASP, Web APIs, and more.
                </p>
                {/* <a href="#Contact" className="btn app-store-btn">
            Contact with us
          </a> */}
              </div>
            </div>
          </div>
          <div className={`row equal ${styles.promo_cards}`}>
            <div
              className={` col-lg-4 ${styles.card}`}
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-layout text-white"></span>
                </div>
                <h5>Straightforward Design</h5>
                <p>
                  Quickly parse data and easily leverage your custom software’s
                  capabilities while enjoying a sleek and straightforward
                  design.
                </p>
              </div>
            </div>
            <div
              className={` col-lg-4 ${styles.card}`}
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-user text-white"></span>
                </div>
                <h5>Intuitive User Experience</h5>
                <p>
                  An intuitive user interface can make or break the user
                  experience, so we include easy-to-use features that
                  accommodate all technological skill levels.
                </p>
              </div>
            </div>
            <div
              className={` col-lg-4 ${styles.card}`}
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-target text-white"></span>
                </div>
                <h5>Accuracy</h5>
                <p>
                  {/* REGERA’s */}Leverage REGERA's business automation
                  capabilities for greater data accuracy and an improved bottom
                  line.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;
