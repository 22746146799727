import { Link } from "react-router-dom";
import styles from "./LatestNewsOne.module.css";
import React from "react";

export default function LatestNewsOne({ bgColor, light }) {
  return (
    <>
      <section id="blog" className={`our-blog-section ${bgColor}`}>
        <div className={`container ${styles.news}`}>
          <div className="row">
            <div className={window.innerWidth <= 1024 ? "" : "col-md-8"}>
              <div className={`section-heading mb-5 `}>
                <h2 className={` ${light ? "text-black" : "text-white"}`} data-aos="fade-right">
                  Our Latest News
                </h2>
                <p className="lead" data-aos="fade-right" data-aos-delay="100">
                  Follow our blog to learn the latest and greatest in custom
                  software development, business automation, software
                  architecture, and important news updates.
                </p>
              </div>
            </div>
          </div>
          <div className={`row ${styles.new_h3}`}>
            <div className={window.innerWidth <= 1024 ? "" : "col-md-4"} data-aos="fade-right" data-aos-delay="600">
              <div className="single-blog-card card border-0 shadow-sm">
                <span className="category position-absolute badge badge-pill badge-primary">
                  IOT
                </span>
                <div className="blog-cover-img">
                  <img
                    src="assets/img/blog/iot.jpeg"
                    className="card-img-top position-relative"
                    alt="blog"
                  />
                </div>
                <div className="card-body">
                  <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">Jan 21, 2019</li>
                      <li className="list-inline-item">
                        <span>45</span> Comments
                      </li>
                      <li className="list-inline-item">
                        <span>10</span> Share
                      </li>
                    </ul>
                  </div>
                  <h3 className=" card-title">
                    <Link to="/blog-details">
                      How IoT is Changing the Business Landscape
                    </Link>
                  </h3>
                  <p className="card-text text-dark">
                    The Internet of Things (IoT) is a technological phenomenon
                    that continues to transform how we interact with technology.
                  </p>
                  <Link to="/blog-details" className="detail-link">
                    Read more <span className="ti-arrow-right"></span>
                  </Link>
                </div>
              </div>
            </div>
            <div className={window.innerWidth <= 1024 ? "" : "col-md-4"} data-aos="fade-right" data-aos-delay="400">
              <div className="single-blog-card card border-0 shadow-sm">
                <span className="category position-absolute badge badge-pill badge-danger">
                  Cloud
                </span>
                <div className="blog-cover-img">
                  <img
                    src="assets/img/blog/2.jpg"
                    className="card-img-top position-relative"
                    alt="blog"
                  />
                </div>

                <div className={`card-body `}>
                  <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">May 26, 2019</li>
                      <li className="list-inline-item">
                        <span>30</span> Comments
                      </li>
                      <li className="list-inline-item">
                        <span>5</span> Share
                      </li>
                    </ul>
                  </div>
                  <h3 className=" card-title">
                    <Link to="/blog-details1">
                      How Switching to the Cloud Leads to Long-Term Cost
                      Effectiveness
                    </Link>
                  </h3>
                  <p className="card-text text-dark">
                    Switching to the cloud can yield significant savings for
                    cost-conscious businesses. But don’t just take our word for
                    it
                  </p>
                  <Link to="/blog-details1" className="detail-link">
                    Read more <span className="ti-arrow-right"></span>
                  </Link>
                </div>
              </div>
            </div>
            <div className={window.innerWidth <= 1024 ? "" : "col-md-4"} data-aos="fade-right" data-aos-delay="200">
              <div className="single-blog-card card border-0 shadow-sm">
                <span className="category position-absolute badge badge-pill badge-info">
                  ERP
                </span>
                <div className="blog-cover-img">
                  <img
                    src="assets/img/blog/3.jpg"
                    className="card-img-top"
                    alt="blog"
                  />
                </div>

                <div className="card-body">
                  <div className="post-meta mb-2">
                    <ul className="list-inline meta-list">
                      <li className="list-inline-item">Apr 25, 2019</li>
                      <li className="list-inline-item">
                        <span>41</span> Comments
                      </li>
                      <li className="list-inline-item">
                        <span>30</span> Share
                      </li>
                    </ul>
                  </div>
                  <h3 className=" card-title">
                    <Link to="/blog-details2">
                      Developing a Quality ERP System for Your Business
                    </Link>
                  </h3>
                  <p className="card-text text-dark">
                    A well-designed Enterprise Resource Planning (ERP) system
                    helps businesses achieve greater organization and
                    coordination of business systems
                  </p>
                  <Link to="/blog-details2" className="detail-link">
                    Read more <span className="ti-arrow-right"></span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
