import React, { useState } from "react";
import styles from "./FeatureImgThree.module.css";
// import GroupIcon from "@mui/icons-material/Group";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CloudIcon from "@mui/icons-material/Cloud";
import CellWifiIcon from "@mui/icons-material/CellWifi";
import StorageIcon from "@mui/icons-material/Storage";
import SpeedIcon from "@mui/icons-material/Speed";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import PsychologyIcon from "@mui/icons-material/Psychology";
import VerticalShadesIcon from "@mui/icons-material/VerticalShades";

export default function FeatureImgThree() {
  const [activeTab, setAtiveTabe] = useState("tab6-1");
  // Implemented functionality to dynamically handle the Tabs
  // Ensures that clicking on a specific icon triggers the display of corresponding content
  // The activeTab state is utilized to track and manage the currently active tab, updating content accordingly
  const handleTabClick = (tabId) => {
    setAtiveTabe(tabId);
  };
  return (
    <>
      <section id="solutions" className={`feature-section ptb-100 mb-5`}>
        <div className={styles.solution_main}>
          <div className={styles.solution_left} data-aos="fade-right">
            {/* solution old */}
            <div className={styles.solution_left_header}>
              <div className={styles.solution_left_header_content}>
                <h2 className="mt-100">Custom Solutions</h2>
                <p>
                  Our team of professionals adds value to each custom software
                  development project we work on. We work collaboratively with
                  businesses to learn their needs and devise a solution that
                  continuously drives improved results. We support various
                  platforms and can migrate existing systems with new ones while
                  executing a flawless implementation process.
                </p>
              </div>
            </div>
            <div className={`img-animation ${styles.left_image}`}>
              <img
                src="assets/img/image-10.jpg"
                className="img-fluid h-100 object-fit-cover"
                alt="download"
              />
            </div>
          </div>
          <div className={styles.solution_right} data-aos="fade-left">
            <div className={`feature-content-wrap ${styles.sol_right_content}`}>
              <ul
                className={`nav nav-tabs feature-tab ${styles.solution_buttons}`}
                data-tabs="tabs"
              >
                <li className="nav-item">
                  <a
                    className={`nav-link  active h6 ${styles.nav_link} `}
                    href="#tab6-1"
                    data-toggle="tab"
                    onClick={() => handleTabClick("tab6-1")}
                  >
                    <span className="ti-group">
                      <BusinessCenterIcon fontSize="20px" />
                    </span>
                    ERP
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    className="nav-link  h6"
                    href="#tab6-2"
                    data-toggle="tab"
                    onClick={() => handleTabClick("tab6-2")}
                  >
                    <span className="">
                      <GroupIcon fontSize="20px" />
                    </span>
                    Cybersecurity
                  </a>
                </li> */}
                <li className="nav-item">
                  <a
                    className="nav-link h6"
                    href="#tab6-10"
                    data-toggle="tab"
                    onClick={() => handleTabClick("tab6-10")}
                  >
                    <span>
                      <VerticalShadesIcon fontSize="20px" />
                    </span>
                    Portals
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link h6"
                    href="#tab6-3"
                    data-toggle="tab"
                    onClick={() => handleTabClick("tab6-3")}
                  >
                    <span className="">
                      <CloudIcon fontSize="20px" />
                    </span>
                    Cloud
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link h6"
                    href="#tab6-4"
                    data-toggle="tab"
                    onClick={() => handleTabClick("tab6-4")}
                  >
                    <span className=" ">
                      <CellWifiIcon fontSize="20px" />
                    </span>
                    IoT
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link h6"
                    href="#tab6-9"
                    data-toggle="tab"
                    onClick={() => handleTabClick("tab6-9")}
                  >
                    <span>
                      <PsychologyIcon fontSize="20px" />
                    </span>
                    AI
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link h6"
                    href="#tab6-5"
                    data-toggle="tab"
                    onClick={() => handleTabClick("tab6-5")}
                  >
                    <span className=" ">
                      <StorageIcon fontSize="20px" />
                    </span>
                    Embedded
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    className="nav-link h6"
                    href="#tab6-8"
                    data-toggle="tab"
                    onClick={() => handleTabClick("tab6-8")}
                  >
                    <span className=" ti-panel"></span>
                    Finetech
                  </a>
                </li> */}
                <li className="nav-item">
                  <a
                    className="nav-link h6"
                    href="#tab6-6"
                    data-toggle="tab"
                    onClick={() => handleTabClick("tab6-6")}
                  >
                    <span>
                      <SpeedIcon fontSize="20px" />
                    </span>
                    SCADA
                  </a>
                </li>
                <li className="nav-item " style={{ marginRight: "15px" }}>
                  <a
                    className="nav-link h6"
                    href="#tab6-7"
                    data-toggle="tab"
                    onClick={() => handleTabClick("tab6-7")}
                  >
                    <span>
                      <DeveloperBoardIcon fontSize="20px" />
                    </span>
                    PLC
                  </a>
                </li>
              </ul>
              <div className="tab-content feature-tab-content">
                <div
                  className={`tab-pane ${
                    activeTab === "tab6-1" ? "active" : ""
                  }`}
                  id="tab6-1"
                >
                  <h5> ERP Solutions</h5>
                  <p>
                    Our ERP solutions streamline operations, reduce costs,
                    create efficiencies, and enhance organizational visibility
                    across business processes. We seamlessly integrate existing
                    systems and real-time data analytics to empower accurate
                    decision-making.
                  </p>
                </div>
                <div
                  className={`tab-pane ${
                    activeTab === "tab6-2" ? "active" : ""
                  }`}
                  id="tab6-1"
                >
                  <h5> Cybersecurity</h5>
                  <p>
                    {/* lorem 50 */}
                    Effectively protect your digital assets with our
                    comprehensive cybersecurity measures. We offer
                    state-of-the-art proactive threat detection to keep your
                    business safe. Through our security protocols, we ensure
                    that your specific business risks are addressed in order to
                    create a bespoke shield of defense against cyber threats.
                  </p>
                </div>
                <div
                  className={`tab-pane ${
                    activeTab === "tab6-10" ? "active" : ""
                  }`}
                  id="tab6-10"
                >
                  <h5>Web Portals</h5>
                  <p>
                    Our goal is to create better accessibility for our clients
                    without compromising their data security. Featuring expertly
                    engineered portals, you can create seamless operations
                    through one convenient access point.
                  </p>
                </div>
                <div
                  className={`tab-pane ${
                    activeTab === "tab6-3" ? "active" : ""
                  }`}
                  id="tab6-3"
                >
                  <h5>Cloud Services</h5>
                  <p>
                    REGERA offers hosted cloud computing services that are
                    scalable, cost-effective, and reliable. Our cloud hosting
                    services are highly secure and flexible to ever-changing
                    business demands.
                  </p>
                </div>
                <div
                  className={`tab-pane ${
                    activeTab === "tab6-4" ? "active" : ""
                  }`}
                  id="tab6-4"
                >
                  <h5>IoT</h5>
                  <p>
                    The IIoT and Industry 4.0 fields our team has worked in
                    since our inception. We can add business intelligence to
                    existing systems by providing products like smart devices,
                    QR scanners, RFID, barcodes, and other items to seamlessly
                    collect, analyze, and exchange data between plant
                    information systems.
                  </p>
                </div>
                <div
                  className={`tab-pane ${
                    activeTab === "tab6-9" ? "active" : ""
                  }`}
                  id="tab6-9"
                >
                  <h5>AI</h5>
                  <p>
                    The potential of AI is boundless, bringing endless
                    possibilities to the manufacturing and commercial business
                    landscapes. Our AI solutions incorporate machine learning
                    and task automation while gleaning actionable insights from
                    business data, allowing for better business outcomes.
                  </p>
                </div>
                <div
                  className={`tab-pane ${
                    activeTab === "tab6-5" ? "active" : ""
                  }`}
                  id="tab6-5"
                >
                  <h5>Embedded Systems</h5>
                  <p>
                    We offer tailored expertise and proven solutions for
                    businesses with complex, resource-limited embedded
                    environments. Our team has extensive development experience
                    in embedded systems for data acquisition, closed-loop
                    control, algorithm acceleration, and motor controllers.
                  </p>
                </div>
                <div
                  className={`tab-pane ${
                    activeTab === "tab6-8" ? "active" : ""
                  }`}
                  id="tab6-8"
                >
                  <h5>Finetech</h5>
                  <p>
                    Our fintech solutions are made to help you streamline
                    financial management with optimal security. We offer
                    real-time analytics for fraud prevention and compliance.
                    With data encryption and secure communication channels, we
                    are able to protect sensitive information in all
                    transactions.
                  </p>
                </div>
                <div
                  className={`tab-pane ${
                    activeTab === "tab6-6" ? "active" : ""
                  }`}
                  id="tab6-6"
                >
                  <h5>SCADA Solutions</h5>
                  <p>
                    SCADA allows for greater control and productivity through
                    business automation. Gain critical visibility into your
                    business processes by monitoring and managing industrial
                    infrastructure while achieving informed business
                    decision-making.
                  </p>
                </div>
                <div
                  className={`tab-pane ${
                    activeTab === "tab6-7" ? "active" : ""
                  }`}
                  id="tab6-7"
                >
                  <h5>PLC</h5>
                  <p>
                    Our experienced team of PLC control engineers is skilled in
                    developing automation system controls for manufacturing
                    plants. This includes turnkey robot systems and modifying
                    new equipment to integrate into existing plant control
                    networks. Many plants we’ve worked with now meet new
                    industry standards with their latest upgrades.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* solution new */}
          {/* <div className="container">
            <div className="solution-top-line mb-5"></div>

            <div className="row">
              <div className="col-lg-4">
                <div className="d-flex">
                  <div className="fs-1 h5">1</div>
                  <h5 className="ml-2">Preparation</h5>
                </div>
              </div>
              <div className="col-lg-4">
                <ul className="list-unstyled m-0 p-0 solution-list">
                  <li>Requirements analysis</li>
                  <li>Scope definition</li>
                  <li>Expertise mapping</li>
                </ul>
              </div>
              <div className="col-lg-4">
                <ul className="list-unstyled m-0 p-0 solution-list">
                  <li>Process outlining</li>
                  <li>Roadmap development</li>
                </ul>
              </div>
            </div>
            <div className="row mt-4 border-top pt-4">
              <div className="col-lg-4">
                <div className="d-flex">
                  <div className="fs-1 h5">2</div>
                  <h5 className="ml-2">Team composition</h5>
                </div>
              </div>
              <div className="col-lg-4">
                <ul className="list-unstyled m-0 p-0 solution-list">
                  <li>Candidates approval</li>
                  <li>Team setup </li>
                  <li>Knowledge acquisition</li>
                </ul>
              </div>
              <div className="col-lg-4">
                <ul className="list-unstyled m-0 p-0 solution-list">
                  <li>Infrastructure setup</li>
                  <li>Methodology definition</li>
                </ul>
              </div>
            </div>
            <div className="row mt-4 border-top pt-4">
              <div className="col-lg-4">
                <div className="d-flex">
                  <div className="fs-1 h5">3</div>
                  <h5 className="ml-2">Team integration</h5>
                </div>
              </div>
              <div className="col-lg-4">
                <ul className="list-unstyled m-0 p-0 solution-list">
                  <li>Knowledge sharing</li>
                  <li>Performance optimization</li>
                  <li>Progress reporting</li>
                </ul>
              </div>
              <div className="col-lg-4">
                <ul className="list-unstyled m-0 p-0 solution-list">
                  <li>Team scaling</li>
                  <li>KPI control</li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}
