import LatestNewsOne from "../../components/blogs/LatestNewsOne";
import Contact from "../../components/contact/Contact";
import FaqStatic from "../../components/Faqs/FaqStatic";
import FeatureImgThree from "../../components/features/FeatureImgThree";
import FeatureImgTwo from "../../components/features/FeatureImgTwo";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import Subsribe from "../../components/newsletter/Subsribe";
import Price from "../../components/prices/Price";
// import Promo from "../../components/promo/Promo";
import VideoPromoTwo from "../../components/promo/VideoPromoTwo";
import Screenshot from "../../components/screenshot/Screenshot";
import React from "react";

// import Team from "../../components/team/Team";
// import BrandCarousel from "../../components/testimonial/BrandCarousel";
import HeroFour from "./HeroFour";
import Loader from "../../components/loader";
import Expertise from "../../components/expertise";

export default function HomeFour() {
  const [loader, setLoader] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 5000);
  }, []);
  return (
    <Layout>
      {loader && <Loader/>}
      <Navbar darkBg />
      <HeroFour />
      {/* <Promo /> */}
      <FeatureImgTwo />
      <Expertise/>
      <FeatureImgThree />

      <Price hasBg />
      <FaqStatic />
      <VideoPromoTwo />

      <Screenshot />
      {/* <Team bgColor="gray-light-bg" /> */}
      <LatestNewsOne light />
      <Contact />
      {/* <BrandCarousel /> */}
      <Subsribe />
      <Footer />
    </Layout>
  );
}
