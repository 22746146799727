import React from "react";
// import Comments from "./Comments";
// import Sidebar from "./Sidebar";
import styles from "./career.module.css";
import CareerSidebar from "./CareerSidebar";

export default function DynamicDetails() {
  return (
    <>
      <div className="module ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <article className="post">
                <div className="post-preview">
                  {/* <img
                    src="assets/img/blog/1-w.jpg"
                    alt="article"
                    className="img-fluid"
                  /> */}
                </div>
                <div className="post-wrapper">
                  <div className={`post-header ${styles.main_h1}`} data-aos="fade-right">
                    <h1 className="post-title">We Are Hiring!</h1>
                  </div>
                  <div className={`post-content ${styles.single_blog}`}>
                    <p data-aos="fade-right">
                        {/* dynamic developer details */}
                        We are looking for a talented Dynamics 365 Developer to join our team! As a Dynamics 365 Developer, you will be responsible for implementing new features and fixing defects in our Dynamics 365 system. You will be working with our senior developers to coordinate with other teams on different layers of the infrastructure. Therefore, a commitment to collaborative problem solving, sophisticated design, and quality product is essential.
                    </p>

                    <h5 style={{ fontWeight: "bold", fontSize: "16px" }} data-aos="fade-right">
                      Responsibilities
                    </h5>
                    <p>
                      <ul className="list-disc" data-aos="fade-right">
                        <li>
                            Design, develop, and maintain Dynamics 365 applications using C#
                        </li>
                        <li>Work with Dynamics 365 modules such as Sales, Customer Service, Field Service, Project Service Automation, and Marketing</li>
                        <li>
                            Work with SQL Server database
                        </li>
                        <li>Work with Azure environment</li>
                        <li>
                            Integrate Dynamics 365 applications with other systems
                        </li>
                        <li>
                            Work with Dynamics 365 front-end web technologies such as HTML, CSS, JavaScript, and jQuery
                        </li>
                        <li>
                            Work with Dynamics 365 front-end JavaScript frameworks such as AngularJS, ReactJS, and VueJS
                        </li>
                        <li>
                            Work with Dynamics 365 back-end web frameworks such as Django and Flask
                        </li>
                        <li>
                            Work with Dynamics 365 ORM such as Dynamics 365 ORM and SQLAlchemy
                        </li>
                        <li>
                            Work with Dynamics 365 Power Platform
                        </li>
                      </ul>
                    </p>

                    <h5 style={{ fontWeight: "bold", fontSize: "16px" }} data-aos="fade-right">
                      Requirements
                    </h5>

                    <p data-aos="fade-right">
                      <ul className="list-disc">
                        <li>Proven Dynamics 365 development experience</li>
                        <li>Track record of over-achieving quota</li>
                        <li>
                          Strong phone presence and experience dialing dozens of
                          calls per day
                        </li>
                        <li>
                          Proficient with corporate productivity and web
                          presentation tools
                        </li>
                        <li>
                          Experience working with Salesforce.com or similar CRM
                        </li>
                        <li>
                          Excellent verbal and written communications skills
                        </li>
                        <li>Strong listening and presentation skills</li>
                        <li>
                          Ability to multi-task, prioritize, and manage time
                          effectively
                        </li>
                        <li>BA/BS degree or equivalent</li>
                      </ul>
                    </p>

                    <h5 style={{ fontWeight: "bold", fontSize: "16px" }} data-aos="fade-right">
                      Benefits
                    </h5>

                    <p data-aos="fade-right">
                      <ul className="list-disc">
                        <li>Competitive salary</li>
                        <li>Health insurance</li>
                        <li>Flexible working hours</li>
                        <li>Work from home</li>
                        <li>Performance bonus</li>
                        <li>Travel allowance</li>
                        <li>Training and development</li>
                      </ul>
                    </p>

                    <h5 style={{ fontWeight: "bold", fontSize: "16px" }} data-aos="fade-right">
                      How to Apply?
                    </h5>

                    <p data-aos="fade-right">
                      Please send your resume to{" "}
                      <a href="mailto:info@regera.tech">info@regera.tech</a>
                    </p>
                  </div>
                </div>
              </article>
            </div>
            <CareerSidebar />
          </div>
        </div>
      </div>
    </>
  );
}
