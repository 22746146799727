import React from "react";
// import OwlCarousel from "react-owl-carousel";
import styles from "./Screenshot.module.css";

export default function Screenshot({ hasBg }) {
  // Owl Carousel options
  // const carouselOptions = {
  //   loop: true,
  //   margin: 0,
  //   center: true,
  //   dots: true,
  //   nav: false,
  //   autoplay: true,
  //   responsive: {
  //     0: { items: 1 },
  //     768: { items: 3 },
  //     991: { items: 4 },
  //     1200: { items: 4 },
  //     1920: { items: 4 },
  //   },
  // };

  return (
    <>
      <section className="promo-section ptb-100" id="technologies">
        <div className={`container ${styles.main_container}`}>
          <div className="row">
            <div className="col-lg-6 col-md-10" data-aos="fade-up">
              <div className="section-heading mb-5">
                {/* <button className="text-uppercase color-secondary sub-title">
                  Key features
                </button> */}
                <h2 className="mb-6">Explore Our Tech Stack</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div data-aos="fade-up" data-aos-delay="300" className={`col-lg-4 col-md-6 mb-lg-0 `}>
              <div
                className={`card single-promo-card single-promo-hover d-flex flex-column ${styles.main_card}`}
              >
                <div className={`card-body ${styles.card}`}>
                  <div className="pb-2">
                    {/* <button className="ti-credit-card icon-md color-secondary"></button> */}
                  </div>
                  <h5>Programming Languages</h5>
                  <div className=" pt-2 pb-3 tech-grid">
                    <button>Java</button>
                    <button>Python</button>
                    <button>Swift</button>
                    <button>Kotlin</button>

                    <button>SQL</button>

                    <button>PhP</button>

                    <button>C++</button>
                    <button>Ruby</button>

                    <button>R</button>
                    <button>Go</button>
                    <button>C</button>
                    <button>C#</button>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-delay="300" className="col-lg-4 col-md-6 mb-lg-0">
              <div
                className={`card single-promo-card single-promo-hover d-flex flex-column ${styles.main_card}`}
              >
                <div className={`card-body ${styles.card}`}>
                  <div className="pb-2">
                    {/* <button className="ti-control-record icon-md color-secondary"></button> */}
                  </div>
                  <h5>Frameworks & Libraries</h5>
                  <div className=" pt-2 pb-3 tech-grid">
                    <button>Django</button>
                    <button className="cols-2">Ruby on Rails</button>
                    <button>NET Core</button>
                    <button>JQuery</button>
                    <button>Laravel</button>
                    <button>Spring</button>
                    <button>Vue.js</button>
                    <button>ASP.NET</button>
                    <button>React</button>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-delay="400" className="col-lg-4 col-md-6 mb-lg-0">
              <div
                className={`card single-promo-card single-promo-hover d-flex flex-column ${styles.main_card}`}
              >
                <div className={`card-body ${styles.card}`}>
                  <div className="pb-2"></div>
                  <h5>Operating Systems</h5>
                  <div className=" pt-2 pb-3 tech-grid">
                    <button>Windows</button>
                    <button>Mac OS</button>

                    <button>Android</button>
                    <button>iOS</button>

                    <button>Linux</button>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-delay="500" className="col-lg-4 col-md-6 mb-lg-0">
              <div
                className={`card single-promo-card single-promo-hover d-flex flex-column ${styles.main_card}`}
              >
                <div className={`card-body ${styles.card}`}>
                  <div className="pb-2">
                    {/* <button className="ti-receipt icon-md color-secondary"></button> */}
                  </div>
                  <h5>Platforms</h5>
                  <div className=" pt-2 pb-3 tech-grid">
                    <button>Acumatica</button>
                    <button>Atlassian</button>
                    <button>UiPath</button>
                    <button>Microsoft</button>
                    <button>SAP Commerce</button>
                    <button>Odoo</button>
                    <button>Pega</button>
                    <button>Salesforce</button>
                  </div>
                </div>
              </div>
            </div>
            {/* new */}
            <div data-aos="fade-up" data-aos-delay="600" className="col-lg-4 col-md-6 mb-lg-0">
              <div
                className={`card single-promo-card single-promo-hover d-flex flex-column ${styles.main_card}`}
              >
                <div className={`card-body ${styles.card}`}>
                  <div className="pb-2">
                    {/* <button className="ti-credit-card icon-md color-secondary"></button> */}
                  </div>
                  <h5>Mobile Technologies</h5>
                  <div className=" pt-2 pb-3 tech-grid">
                    <button>Mobile Angular UI</button>
                    <button>PhoneGap</button>
                    <button>React Native</button>
                    <button>JQuery Mobile</button>
                    <button> Ionic</button>
                    <button>Flutter</button>
                    <button>Xamarin</button>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-delay="700" className="col-lg-4 col-md-6 mb-lg-0">
              <div
                className={`card single-promo-card single-promo-hover d-flex flex-column ${styles.main_card}`}
              >
                <div className={`card-body ${styles.card}`}>
                  <div className="pb-2">
                    {/* <button className="ti-credit-card icon-md color-secondary"></button> */}
                  </div>
                  <h5>Cloud</h5>
                  <div className=" pt-2 pb-3 tech-grid">
                    <button>AWS</button>
                    <button>Rackspace</button>
                    <button>Apprenda</button>
                    <button>Microsoft Azure</button>
                    <button> Google Cloud</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
