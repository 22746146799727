import React from "react";

export default function Accordion({ Title }) {
  return (
    <>
      <section className={`container accordions pt-5 ${Title ? "ptb-100" : ""}`}>
        {Title ? (
          <div className="row">
            <div className="col-lg-8 col-md-9">
              <div className="section-heading mb-5">
                <h2>Frequently Asked Questions</h2>
                <p className="lead">
                  Quickly morph client-centric results through performance based
                  applications. Proactively facilitate professional human
                  capital for cutting-edge.
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="row">
          <div className="col-lg-6">
            <div id="accordion-1" className="accordion accordion-faq">
              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-1"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-1"
                  aria-expanded="false"
                  aria-controls="collapse-1-1"
                >
                  <h6 className="mb-0">
                    <span className="ti-receipt mr-3 color-secondary"></span>
                    What’s included in your technology suite?
                  </h6>
                </div>
                <div
                  id="collapse-1-1"
                  className="collapse"
                  aria-labelledby="heading-1-1"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                      Our technology suite includes several business-critical
                      technologies, including business automation, AI and
                      machine learning, custom software development and
                      solutions, ERP solutions, IIoT solutions, PLCs, SCADA
                      solutions, and more.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-2"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-2"
                  aria-expanded="false"
                  aria-controls="collapse-1-2"
                >
                  <h6 className="mb-0">
                    <span className="ti-gallery mr-3 color-secondary"></span>
                    What size businesses do you serve?
                  </h6>
                </div>
                <div
                  id="collapse-1-2"
                  className="collapse"
                  aria-labelledby="heading-1-2"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                      We serve businesses of all sizes and scales, including
                      SMBs, enterprises, and startups. Thanks to our scalable
                      and adaptable solutions, our technology can grow alongside
                      your business and continue to drive success.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-1-3"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-1-3"
                  aria-expanded="false"
                  aria-controls="collapse-1-3"
                >
                  <h6 className="mb-0">
                    <span className="ti-wallet mr-3 color-secondary"></span>{" "}
                    What’s the user experience like?
                  </h6>
                </div>
                <div
                  id="collapse-1-3"
                  className="collapse"
                  aria-labelledby="heading-1-3"
                  data-parent="#accordion-1"
                >
                  <div className="card-body">
                    <p>
                      Creating an unparalleled user experience is top of mind
                      when designing our custom software applications and
                      solutions. Using intuitive interfaces and user-friendly
                      features, we can ensure total user accessibility
                      regardless of technological skill.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div id="accordion-2" className="accordion accordion-faq">
              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-1"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-1"
                  aria-expanded="false"
                  aria-controls="collapse-2-1"
                >
                  <h6 className="mb-0">
                    <span className="ti-receipt mr-3 color-secondary"></span>
                    {/* Why choose REGERA? */}
                    Why choose REGERA?
                  </h6>
                </div>
                <div
                  id="collapse-2-1"
                  className="collapse"
                  aria-labelledby="heading-2-1"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                      For businesses seeking improved efficiency, innovative
                      solutions, and enhanced business performance, REGERA
                      offers a comprehensive suite of custom software and
                      business automation technologies designed to meet their
                      specific needs.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-2"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-2"
                  aria-expanded="false"
                  aria-controls="collapse-2-2"
                >
                  <h6 className="mb-0">
                    <span className="ti-lock mr-3 color-secondary"></span>
                    Can you tailor your technology to meet my business’s needs?
                  </h6>
                </div>
                <div
                  id="collapse-2-2"
                  className="collapse"
                  aria-labelledby="heading-2-2"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                      Business software customization is our core service, and
                      we will work closely with you to devise a custom software
                      solution that accounts for all your business needs while
                      creating a seamless user experience.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card">
                <div
                  className="card-header py-4"
                  id="heading-2-3"
                  data-toggle="collapse"
                  role="button"
                  data-target="#collapse-2-3"
                  aria-expanded="false"
                  aria-controls="collapse-2-3"
                >
                  <h6 className="mb-0">
                    <span className="ti-widget mr-3 color-secondary"></span>{" "}
                    How’s your support?
                  </h6>
                </div>
                <div
                  id="collapse-2-3"
                  className="collapse"
                  aria-labelledby="heading-2-3"
                  data-parent="#accordion-2"
                >
                  <div className="card-body">
                    <p>
                      Our friendly and responsive support is at the heart of our
                      customers’ success. We offer 24/7 live phone support and
                      strive for timely resolutions and an exceptional customer
                      experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
